// Taken from
// https://github.com/twbs/bootstrap/pull/31285/files

$modal-sidebar-width: 350px !default;
$modal-sidebar-max-width: 80% !default;

.modal-left,
.modal-right {
  .modal-dialog {
    width: $modal-sidebar-width;
    max-width: $modal-sidebar-max-width;
    height: 100%;
    margin: 0;
    transform: translate(0);
    @include transition(transform 0.2s);
  }

  .modal-content {
    height: inherit;
    border: 0;
    @include border-radius(0);
  }

  .modal-body {
    overflow-y: auto;
  }

  &.modal.show {
    .modal-dialog {
      transform: translateX(0);
    }
  }
}

.modal.modal-right {
  .modal-dialog {
    margin-left: auto;
    transform: translateX(100%);
  }

  // &.show {
  //   .modal-dialog {
  //     transform: translateX(0);
  //   }
  // }
}

.modal.modal-left {
  .modal-dialog {
    margin-right: auto;
    transform: translateX(-100%);
  }

  // &.show {
  //   .modal-dialog {
  //     transform: translateX(0);
  //   }
  // }
}
