.model-table {
  // Horizontal scrolling
  overflow-x: auto;

  .model-table-row-clickable {
    cursor: pointer;
  }
}

.model-card {
  cursor: pointer;
}

.model-create-form,
.model-edit-form {
  // Mark required fields with a red star
  .required:after {
    content: ' *';
    color: red;
  }
}
