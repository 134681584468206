@media print {
  @media (min-width: 576px) {
    .ml-sm-auto,
    .mx-sm-auto {
      margin-left: 0 !important;
    }
  }

  .nav-sidebar {
    display: none !important;
  }
}

.pointer {
  cursor: pointer;
}
